import React, { useState } from "react";
import axios from "axios";
import useAxios from "axios-hooks";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import RGL, { WidthProvider } from "react-grid-layout";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, DialogActions, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomizedSelect from "../../components/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Screen from "../../components/Screen";
import ScreenContext, {
  defaultScreenContext,
} from "../../components/Screen/ScreenContext";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { paletteOptions } from "../../palettes/paletteFactory";
import { themeOptions } from "../../themes/themeFactory";
import { map as layoutMap } from "../../layouts/layoutFactory";
import fontList from "../../fonts/fontList";
import * as appFunc from "../../App.Func";
import { buildScreenModel } from "../../components/Screen/ScreenActions";
import "./Editor.css";
import { nanoid } from "nanoid";
import {ColorPicker} from '../../components/ColorPicker';

export let ReactGridLayout = WidthProvider(RGL);
export const availableIncrements = ['Ounce (Default)', 'Gram'];

const Editor = (props) => {
  const { screenId } = props;
  const [{ data: screenData, loading: screenLoading }] = useAxios(
    `/Screen/${screenId}`
  );
  // Set hooks
  const [editMode] = useState(true);
  const [selectedScreen, setSelectedScreen] = useState();
  const [taxData, setTaxData] = useState(null);
  const [isInit, setIsInit] = useState(true);
  const [screenSaving, setScreenSaving] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState();
  const [openSettings, setOpenSettings] = useState(false);
  const [fontOverrides, setFontOverrides] = useState({});
  const [scrollOverride, setScrollOverride] = useState("Default");
  const [openBG, setOpenBG] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState({ display: "" });
  const [screenName, setScreenName] = useState("");
  const [nameCheck, setNameCheck] = useState(true);
  const [increments, setIncrements] = useState('Ounce (Default)');

  const [backgroundColor, setBackgroundColor] = useState(
    selectedScreen?.config?.backgroundColor || ""
  );

  // Builds the areas for the selected screen and sets the Hook.
  // Sets editMode to true so that AreaComponent
  const onScreenChange = async (screen) => {
    screen.editMode = true;
    //! Might need to add more checks if errors occur
    if (!screen.config) screen.config = { ...defaultScreenContext.config };

    if (typeof screen.config.themeId !== "string")
      screen.config = { ...defaultScreenContext.config };
    
    const newScreen = {
      ...screen,
      model: await buildScreenModel(screen),
    };
    
    if (screen.config.fontOverrides) {
      setFontOverrides(screen.config.fontOverrides);
    }
    
    if (screen.config.scrollOverride) {
      setScrollOverride(screen.config.scrollOverride);
    }
    
    if(screen.config.autoName !== undefined) {
      setNameCheck(screen.config.autoName);
    }

    console.log("Screen Config", screen.config);
    
    setIncrements(screen.config.increments || 'Ounce (Default)')
    setScreenName(screen.name);
    setBackgroundColor(screen.config.backgroundColor || null);
    setSelectedBackground(screen.config.background || { display: "" });
    setSelectedScreen(newScreen);
  };

  if (isInit && !screenLoading) {
    if (screenData && screenData.config === null) {
      screenData.config = defaultScreenContext.config;
    } else {
      screenData.config = JSON.parse(screenData.config);
    }
    if (typeof screenData.config.brandingSetting === "undefined") {
      screenData.config.brandingSetting = true;
    }
    onScreenChange(screenData);
    setIsInit(false);
  }
  
  if(!taxData) {
    axios
      .get(`${appFunc.getApiUrl()}/Store/ByScreenId/${screenId}`)
      .then((response) => {
        setTaxData(response.data);
      });
  }

  const clearBackgroundImage = () => {
    const newScreen = { ...selectedScreen };
    // Remove the background configuration from the screen
    if (newScreen.config.background) {
      delete newScreen.config.background;
    }
    onScreenChange(newScreen);
    // Reset the selected background state
    setSelectedBackground({ display: "" });
  };
  
  const onPaletteChange = async (option) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        paletteId: option,
      },
    };

    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onThemeChange = async (option) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        themeId: option,
        paletteId: option,
      },
    };

    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onHeaderAlignChange = async (option) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        headerAlignment: option,
      },
    };

    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onOrientationChange = (option) => {
    const screen = {
      ...selectedScreen,
      orientation: option,
    };

    setSelectedScreen(screen);
  };

  const onBrandingToggle = async () => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        brandingSetting: !selectedScreen.config.brandingSetting,
      },
    };
    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onSocialToggle = async () => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        socialSetting: !selectedScreen.config.socialSetting,
      },
    };
    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };

  const handleSettingsClose = () => {
    setOpenSettings(false);
  };

  const handleNameChange = (event) => {
    setScreenName(event.target.value);
  }

  const handleNameCheck = () => {
    setNameCheck(!nameCheck);
  }

  const onSettingsSave = async () => {
    setOpenSettings(false);
    const screen = {
      ...selectedScreen,
      name: screenName,
      config: {
        ...selectedScreen.config,
        fontOverrides: fontOverrides,
        scrollOverride: scrollOverride,
        autoName: nameCheck,
        increments
      },
    };
    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onLayoutChange = async (option) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        layoutId: layoutMap[option].id,
      },
    };

    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onMediaHandleChange = async (event) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        socialMediaHandle: event.target.value,
      },
    };

    // screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onLogoChange = (event) => {
    setSelectedLogo(event.target.files[0]);
  };

  const uploadImage = (imageType) => {
    const uniqueId = nanoid();
    const fd = new FormData();
    const newScreen = { ...selectedScreen };

    if (imageType === "logos") {
      let parts = selectedLogo.name.split(".");
      parts[0] += "_" + uniqueId;
      let newName = parts.join(".");
      fd.append("file", selectedLogo, newName);
      newScreen.config.logo = {
        folder: imageType,
        fileName: newName,
      };
    } else if (imageType === "backgrounds") {
      let parts = selectedBackground.image.name.split(".");
      parts[0] += "_" + uniqueId;
      let newName = parts.join(".");
      fd.append("file", selectedBackground.image, newName);
      newScreen.config.background = {
        folder: imageType,
        fileName: newName,
      };
    }

    axios
      .post(`${appFunc.getApiUrl()}/Blob/${imageType}/uploadfile`, fd, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      })
      .then((res) => {
        onScreenChange(newScreen);
      });
  };

  // Pass in new area in config style
  const onAreaChange = async (area) => {
    const otherAreas = selectedScreen.config.areas.filter(
      (a) => a.id !== area.id
    );
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        areas: otherAreas.concat([
          {
            ...area,
          },
        ]),
      },
    };
    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  const onBrandingChange = async (branding) => {
    const screen = {
      ...selectedScreen,
      config: {
        ...selectedScreen.config,
        socialMediaColor: branding,
      },
    };

    screen.model = await buildScreenModel(screen);
    setSelectedScreen(screen);
  };

  //Font Overrides
  const onFontChange = (event, fontArea, isColor) => {
    // console.log(event, fontArea, isColor);
    // console.log(fontOverrides);
    
    const fontSettings = {
      ...fontOverrides,
    };
    fontSettings[fontArea] = isColor ? event : event.target.value;
    setFontOverrides(fontSettings);
  };

   const onIncrementsChange = (event) => {
    setIncrements(event.target.value);
  };

  const onScrollChange = (event) => {
    setScrollOverride(event.target.value);
  };

  const onSaveScreen = (screen) => {
    setScreenSaving(true);
    console.log('saving screen');
    console.log(screen);

    for (let area in screen.config.areas) {
      delete screen.config.areas[area].config;
      delete screen.config.areas[area].data;
    }
    let postData = {
      ...screen,
      config: JSON.stringify(screen.config),
    };
    if(nameCheck) {
      let totalAreas = layoutMap.filter(layout => layout.id === screen.config.layoutId)[0].value.length;
      let categories = screen.config.areas.sort((a, b) => a.id < b.id ? -1: 1).slice(0, totalAreas).map(area => area.category).reduce((acc, cur) => {
        for (let i = 0; i < cur.length; i++) {
          if(!acc.includes(cur[i])) {
            acc.push(cur[i]);
          }
        }
        return acc;
      }, []);
      let screenName = `${postData.orientation} - ${categories.join(', ')}`;
      postData.name = screenName;
      setScreenName(`${postData.orientation} - ${categories.join(', ')}`);
    }

    console.log(postData);

    axios
      .post(`${appFunc.getApiUrl()}/Screen/${screen.id}`, postData)
      .then(function (response) {
        setScreenSaving(false);
        if (response.status !== 200) {
          alert(`Saved failed with message: ${JSON.stringify(response)}`);
        }
      });
  };
  const editModeStyle = { display: editMode ? "" : "none" };
  const ScreenLink = (props) => (
    <Link to="/screen" {...props} target="_blank" />
  );

  const fontSizes = [];
  for (let i = 8; i <= 80; i++) {
    fontSizes.push(i);
  }

  const paddingSizes = [];
  for (let i = 1; i <= 50; i++) {
    paddingSizes.push(i);
  }

  const scrollSpeeds = [];
  for (let i = 10; i<= 40; i++) {
    scrollSpeeds.push(i);
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        {!screenSaving && selectedScreen && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2} style={editModeStyle}>
              <CustomizedSelect
                id="layout-selector"
                label="Select Layout"
                value={selectedScreen.config.layoutId}
                items={layoutMap}
                onChange={onLayoutChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} style={editModeStyle}>
              <CustomizedSelect
                id="palette-selector"
                label="Select Palette"
                value={selectedScreen.config.paletteId}
                items={paletteOptions()}
                onChange={onPaletteChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} style={editModeStyle}>
              <CustomizedSelect
                id="theme-selector"
                label="Select Theme"
                value={selectedScreen.config.themeId}
                items={themeOptions()}
                onChange={onThemeChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} style={editModeStyle}>
              <CustomizedSelect
                id="orientation-selector"
                label="Select Orientation"
                value={selectedScreen.orientation}
                items={[
                  { id: "H", label: "Horizontal" },
                  { id: "V", label: "Vertical" },
                ]}
                onChange={onOrientationChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} style={editModeStyle}>
              <CustomizedSelect
                id="heeder-alignment-selector"
                label="Header Alignment"
                value={selectedScreen.config.headerAlignment || "default"}
                items={[
                  { id: "default", label: "Default" },
                  { id: "left", label: "Left" },
                  { id: "center", label: "Center" },
                  { id: "right", label: "Right" },
                ]}
                onChange={onHeaderAlignChange}
              />
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={2}
              style={{ ...editModeStyle, paddingLeft: "22px" }}
            >
              <Button
                onClick={() => {
                  handleSettingsOpen();
                }}
                variant="contained"
                style={{ marginBottom: "6px" }}
              >
                Settings
              </Button>
              <Button
                component={ScreenLink}
                to={`/screen/${selectedScreen.id}`}
                variant="contained"
                style={{ marginBottom: "6px" }}
              >
                View
              </Button>
              <Button
                onClick={() => {
                  setOpenBG(true);
                }}
                variant="contained"
                style={{ marginBottom: "6px" }}
              >
                Background
              </Button>
              <Button
                onClick={() => {
                  onSaveScreen(selectedScreen);
                }}
                variant="contained"
                style={{
                  backgroundColor: "#3AAD49",
                  color: "#FFF",
                }}
              >
                Save
              </Button>
            </Grid>

            <Dialog
              open={openSettings}
              onClose={handleSettingsClose}
              fullWidth={true}
              maxWidth="lg"
            >
              <DialogTitle id="settings-dialog-title">
                Extra Settings
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Title Font</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="font-selector-title"
                        label="Title Font"
                        value={
                          fontOverrides.titleFont
                            ? fontOverrides.titleFont
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "titleFont");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontList.map((font) => {
                          return (
                            <MenuItem value={font} style={{ fontFamily: font }}>
                              {font}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <ColorPicker
                      title={`Title Color (${fontOverrides.titleColor})`}
                      color={fontOverrides.titleColor}
                      onChange={(color) =>
                        onFontChange(color.hex, "titleColor", true)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Title Size</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="size-selector-title"
                        label="Title Size"
                        value={
                          fontOverrides.titleSize
                            ? fontOverrides.titleSize
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "titleSize");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontSizes.map((size) => {
                          return (
                            <MenuItem value={size} style={{ fontSize: size }}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Header Font</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="font-selector-header"
                        label="Header Font"
                        value={
                          fontOverrides.headerFont
                            ? fontOverrides.headerFont
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "headerFont");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontList.map((font) => {
                          return (
                            <MenuItem value={font} style={{ fontFamily: font }}>
                              {font}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <ColorPicker
                      title={`Header Color (${fontOverrides.headerColor})`}
                      color={fontOverrides.headerColor}
                      onChange={(color) =>
                        onFontChange(color.hex, "headerColor", true)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Header Size</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="size-selector-header"
                        label="Header Size"
                        value={
                          fontOverrides.headerSize
                            ? fontOverrides.headerSize
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "headerSize");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontSizes.map((size) => {
                          return (
                            <MenuItem value={size} style={{ fontSize: size }}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Data Font</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="font-selector-data"
                        label="Data Font"
                        value={
                          fontOverrides.dataFont
                            ? fontOverrides.dataFont
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "dataFont");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontList.map((font) => {
                          return (
                            <MenuItem value={font} style={{ fontFamily: font }}>
                              {font}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <ColorPicker
                      title={`Data Color (${fontOverrides.dataColor})`}
                      color={fontOverrides.dataColor}
                      onChange={(color) =>
                        onFontChange(color.hex, "dataColor", true)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Data Size</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="size-selector-data"
                        label="Data Size"
                        value={
                          fontOverrides.dataSize
                            ? fontOverrides.dataSize
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "dataSize");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {fontSizes.map((size) => {
                          return (
                            <MenuItem value={size} style={{ fontSize: size }}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Data Padding</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="padding-selector-header"
                        label="Data Padding"
                        value={
                          fontOverrides.dataPadding
                            ? fontOverrides.dataPadding
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "dataPadding");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {paddingSizes.map((size) => {
                          return <MenuItem value={size}>{size}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Header Padding</InputLabel>
                      <Select
                        style={{ minWidth: "120px" }}
                        id="padding-selector-header"
                        label="Header Padding"
                        value={
                          fontOverrides.headerPadding
                            ? fontOverrides.headerPadding
                            : "Default"
                        }
                        onChange={(event) => {
                          onFontChange(event, "headerPadding");
                        }}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        {paddingSizes.map((size) => {
                          return <MenuItem value={size}>{size}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Scroll Speed (px/s)</InputLabel>
                      <Select
                        style={{ minWidth: "140px" }}
                        id="scroll-selector-header"
                        label="Scroll Speed"
                        value={scrollOverride ? scrollOverride : "Default"}
                        onChange={(event) => {
                          onScrollChange(event);
                        }}
                      >
                        <MenuItem value="Default">Default (25)</MenuItem>
                        {scrollSpeeds.map((speed) => {
                          return <MenuItem value={speed}>{speed}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <Button
                      onClick={() => {
                        onBrandingToggle();
                      }}
                      variant="contained"
                    >
                      Toggle Branding
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <Button
                      onClick={() => {
                        onSocialToggle();
                      }}
                      variant="contained"
                    >
                      Toggle Social
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <TextField
                      id="screen-name"
                      label="Screen Name"
                      value={screenName}
                      onChange={handleNameChange}
                      focused={true} //Required otherwise when selecting area text and title overlap
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={nameCheck}
                          onChange={handleNameCheck}
                          name="autoName"
                        />
                      }
                      label="Auto Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={editModeStyle}>
                    <FormControl>
                      <InputLabel>Flower Header Increments</InputLabel>
                      <Select
                        style={{ minWidth: "200px" }}
                        id="size-selector-header"
                        label="Header Size"
                        value={increments}
                        onChange={onIncrementsChange}
                      >
                        {availableIncrements.map((el) => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onSettingsSave}>Save Settings</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={1} style={editModeStyle}>
              <Dialog
                open={openBG}
                onClose={() => {
                  setOpenBG(false);
                }}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle id="background-dialog-title">
                  Screen Background Settings
                </DialogTitle>
                <DialogContent>
                  <Box sx={{ p: 2 }}>
                    {/* Background Image Section */}
                    <Box sx={{ mb: 4 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ mb: 1, fontWeight: 500 }}
                      >
                        Background Image
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{ marginTop: "-8px" }}
                      >
                        Note: background image takes precedent over background
                        color
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, marginTop: "12px" }}>
                        <Button variant="outlined" component="label">
                          Choose File
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(event) => {
                              console.log(event.target.files[0]);

                              setSelectedBackground({
                                image: event.target.files[0],
                                fileName: event.target.files[0].name,
                                display: URL.createObjectURL(
                                  event.target.files[0]
                                ),
                              });
                            }}
                          />
                        </Button>
                        {(selectedScreen.config.background ||
                          selectedBackground.fileName) && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={clearBackgroundImage}
                          >
                            Clear Background
                          </Button>
                        )}
                      </Box>
                      {selectedBackground.fileName && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: "12px" }}
                        >
                          <strong>Selected:</strong>{" "}
                          {selectedBackground.fileName}
                        </Typography>
                      )}
                    </Box>

                    {/* Background Color Section */}
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ mb: 2, fontWeight: 500 }}
                      >
                        Background Color
                      </Typography>
                      <Box
                        sx={{
                          p: 2,
                          border: "1px solid #e0e0e0",
                          borderRadius: 1,
                          bgcolor: "#f5f5f5",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box>
                            <Box
                              sx={{
                                width: 48,
                                height: 48,
                                borderRadius: 1,
                                border: "2px solid #fff",
                                boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
                                backgroundColor: backgroundColor || "#ffffff",
                              }}
                            />
                          </Box>
                          <Box sx={{ width: 150, height: 48 }}>
                            <ColorPicker
                              title={`Background Color (${backgroundColor})`}
                              color={backgroundColor}
                              onChange={(color) =>
                                setBackgroundColor(color.hex)
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{ px: 3, py: 2, borderTop: "1px solid #e0e0e0" }}
                >
                  <Button
                    onClick={() => {
                      setOpenBG(false);
                    }}
                    color="inherit"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (selectedBackground.image) {
                        uploadImage("backgrounds");
                      }

                      const newScreen = { ...selectedScreen };
                      newScreen.config.backgroundColor = backgroundColor;
                      onScreenChange(newScreen);

                      setOpenBG(false);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={12}>
              <ScreenContext.Provider
                value={{
                  ...selectedScreen.model,
                  taxData,
                  editMode,
                  socialMediaHandle: selectedScreen.config.socialMediaHandle,
                  orientation: selectedScreen.orientation,
                  onMediaHandleChange,
                  onLogoChange,
                  config: selectedScreen.config,
                }}
              >
                <Screen
                  onAreaChange={onAreaChange}
                  onBrandingChange={onBrandingChange}
                  uploadImage={uploadImage}
                  editMode={editMode}
                />
              </ScreenContext.Provider>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Editor;
